import data from '../pages/team/view/data.json'
import { players } from '../pages/profile/data.json'

export async function getLeftMenuData(role) {
  const playersMenu = []
  data.players.DEFENDERS.map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/profile/${player.nickname}`,
    }),
  )
  playersMenu.push({
    title: 'Fares Siddiqui',
    key: 'fares-siddiqui',
    url: `/profile/fares-siddiqui`,
  })
  const deviceMenu = []
  players.map(player =>
    deviceMenu.push({
      title: player.deviceID,
      key: player.deviceID,
      url: `/device/${player.deviceID}`
    })
  )
  console.log("deviceMenu",deviceMenu)
  const superAdminMenu = [
    {
      title: 'Consumers',
      key: 'consumers',
      url: '/consumers',
    },
    {
      title: 'Coaches',
      key: 'coaches',
      url: '/coaches',
    },

    {
      title: 'Settings',
      key: 'settings',
      icon: 'fa fa-cog',
      children: [
        {
          title: 'Users',
          key: 'superadmin',
          url: '/superadmin',
        },
        {
          title: 'Calendar',
          key: 'calendar',
          url: '/event',
        },
        {
          title: 'Companies',
          key: 'companies',
          url: '/company',
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fa fa-group',
      display: false,
      children: [
        {
          title: 'Coaches',
          key: 'coaches',
          url: '/coaches',
        },
        {
          title: 'Consumers',
          key: 'consumers',
          url: '/consumers',
        },
        {
          title: 'Admin',
          key: 'superadmin',
          url: '/superadmin',
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fa fa-group',
      display: false,
      children: [
        {
          title: 'Add Coach',
          key: 'addCoach',
          url: '/coaches/add',
        },
        {
          title: 'Super Admin',
          key: 'superadmin',
          url: '/superadmin',
        },
      ],
    },
    {
      title: 'Companies',
      key: 'companies',
      icon: 'icmn icmn-briefcase',
      url: '/company',
      children: [
        {
          title: 'Add Company',
          key: 'companyAdd',
          icon: 'icmn icmn-briefcase',
          url: '/company/add',
        },
      ],
      display: false,
    },
    {
      title: 'Device Assignment',
      key: 'deviceAssignment',
      icon: 'icmn icmn-calendar',
      url: '/device/assign/manage',
      display: false,
      children: [{ title: 'Assign Device', key: 'assignDevice', url: '/device/assign/add' }],
    },
    {
      title: 'Consumers',
      key: 'consumers',
      url: '/consumers',
      icon: 'icmn icmn-users',
      display: false,
      children: [
        {
          title: 'Profile',
          key: 'profile',
          icon: '',
          children: playersMenu,
        },
      ],
    },
    {
      title: 'Coaches',
      key: 'coaches',
      icon: 'icmn icmn-calendar',
      url: '/event',
      display: false,
      children: [{ title: 'Add Event', key: 'addevent', url: '/event/add' }],
    },
  ]

  const adminMenu = [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: ' icmn icmn-home3',
      url: '/coach/profile',
    },
    {
      title: 'Members',
      key: 'users',
      icon: 'icmn icmn-users',
      url: '/members',
    },
    {
      title: 'Members',
      key: 'users',
      url: '/members',
      icon: 'icmn icmn-home',
      display: false,
      children: playersMenu.concat([
        {
          title: 'Add Member',
          key: 'addMember',
          url: '/members/add',
        },
      ]),
    },
    // {
    //   title: 'Appointments',
    //   key: 'appointments',
    //   icon: 'icmn icmn-calendar',
    //   url: '/event',
    // },
    // {
    //   title: 'Appointments',
    //   key: 'appointments',
    //   icon: 'icmn icmn-calendar',
    //   url: '/event',
    //   display: false,
    //   children: [{ title: 'New Appointment', key: 'addevent', url: '/event/add' }],
    // },
    // {
    //   title: 'Income',
    //   key: 'income',
    //   icon: 'icmn icmn-coin-dollar',
    //   url: '/income',
    // },
    {
      title: 'Manage Devices',
      key: 'device',
      icon: 'fa fa-tasks',
      url: '/device',
      display: false,
      children: deviceMenu
    },
    {
      title: 'Manage Devices',
      key: 'manage_deviceInentory',
      icon: 'fa fa-tasks',
      url: '/inventory',
    },
    {
      title: 'Manage Devices',
      key: 'manage_deviceInentory',
      icon: 'fa fa-tasks',
      url: '/inventory',
      display: false,
      children: [{ title: 'Add Device', key: 'adddevice', url: '/inventory/add' }],
    },
  ]
  return role === 'superAdmin' ? superAdminMenu : adminMenu
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com/react/getting-started',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Dashboards',
      key: 'dashboards',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
      ],
    },
  ]
}

import React from 'react'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { Calendar } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Settings extends React.Component {
  changeSetting = (setting, value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })
  }

  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: false,
      },
    })
  }

  render() {
    const {
      settings: { isSettingsOpen },
    } = this.props

    return (
      <div
        className={isSettingsOpen ? `${styles.settings} ${styles.settingsOpened}` : styles.settings}
      >
        <Scrollbars style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.title}>
              Theme Settings
              <button
                className={`${styles.close} fa fa-times`}
                onClick={this.closeSettings}
                type="button"
              />
            </div>
          </div>
          <Calendar fullscreen={false} />
        </Scrollbars>
      </div>
    )
  }
}

export default Settings

import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'
import data from '../../../../pages/notification/data.json'
import { players } from '../../../../pages/profile/data'

class HomeMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      dataSource: [],
    }
  }

  componentDidMount() {
    const { devices, fwVersions, deviceTypes } = data
    const dataSource = []
    Object.keys(devices).map((deviceKey, index) =>
      dataSource.push({
        key: index,
        device_type: deviceTypes[devices[deviceKey].deviceType],
        fw_version: fwVersions[devices[deviceKey].FWVersion],
        target_ssid: devices[deviceKey].targetSSID,
        softap_ssid: devices[deviceKey].softapSSID,
        range_min: devices[deviceKey].minimumRange,
        range_max: devices[deviceKey].maximumRange,
        fps_set: devices[deviceKey].fpsSet,
        meters_bin: devices[deviceKey].metersPerBin,
        assigned_to: devices[deviceKey].status === 'active' ? 'John' : 'None',
        status: devices[deviceKey].status,
        title: devices[deviceKey].title,
        description: devices[deviceKey].description,
        user: players[index].name, // devices[deviceKey].user,
        nickname: players[index].nickname, // devices[deviceKey].user,
        datetime: devices[deviceKey].datetime
      }),
    )
    this.setState({
      dataSource,
    })
  }

  render() {
    const { dataSource } = this.state
    const menu = (
      <Menu selectable={false} className={styles.activity}>
        {dataSource.map((item) =>
          <Menu.Item className={styles.item}>
            <Link to={`/profile/${item.nickname}`}>
              <i className={`${styles.icon} icmn-star-full`} />
              <div className={styles.inner}>
                <div className={styles.title}>
                  <span className="pull-right"><span className="badge badge-danger">New</span></span>
                  <a href="javascript: void(0);">
                    {item.title}:
                  </a>
                </div>
                <div className={styles.descr}>
                  {item.user} at <small className="text-muted">{item.datetime}</small>
                </div>
              </div>
            </Link>
          </Menu.Item>
        )}

      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <i className="icmn-bell topbar__dropdownIcon" />
        </div>
      </Dropdown>
    )
  }
}

export default HomeMenu
